import React, { useState } from 'react';
import { FaTools, FaCog, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';  // Importar el ícono

const SupportPlans = () => {
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const infoSpring = useSpring({
    opacity: showInfo ? 1 : 0,
    transform: showInfo ? 'translateY(0)' : 'translateY(-20px)',
  });

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          **Planes de Soporte Técnico Online para tu Negocio** | Soporte Remoto Profesional
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          Ofrecemos planes de soporte técnico remoto diseñados para empresas de todos los tamaños. Desde freelancers hasta grandes corporaciones, garantizamos que tu infraestructura tecnológica esté siempre en funcionamiento.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {/* Plan Básico */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaTools className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Básico</h3>
              <p className="mt-2 text-gray-500">
                Soporte remoto para hasta 3 equipos. Respuesta en 24 horas.
              </p>
              <p className="mt-4 text-gray-800 font-bold">$150 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/soporte-basico')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Básico</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Básico incluye soporte remoto para hasta 3 equipos y un máximo de 5 tickets por mes. Ideal para pequeñas empresas o freelancers que no requieren soporte técnico constante.
                </p>
              </animated.div>
            )}
          </div>
          
          {/* Plan Avanzado */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaCog className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Avanzado</h3>
              <p className="mt-2 text-gray-500">
                Soporte remoto para hasta 5 equipos. Respuesta en 12 horas y optimización mensual.
              </p>
              <p className="mt-4 text-gray-800 font-bold">$300 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/soporte-avanzado')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Avanzado</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Avanzado incluye soporte remoto para hasta 5 equipos, hasta 10 tickets por mes, y un tiempo de respuesta más rápido. Ideal para pequeñas y medianas empresas que requieren soporte técnico más frecuente.
                </p>
              </animated.div>
            )}
          </div>

          {/* Plan Premium */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaChartLine className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Premium</h3>
              <p className="mt-2 text-gray-500">
                Soporte remoto para hasta 10 equipos. Respuesta en 2 horas y monitoreo proactivo.
              </p>
              <p className="mt-4 text-gray-800 font-bold">$500 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/soporte-premium')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Premium</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Premium ofrece soporte remoto para hasta 10 equipos, un tiempo de respuesta urgente de menos de 2 horas, monitoreo proactivo y una atención más personalizada. Ideal para empresas de mayor tamaño o que requieren soporte técnico constante y especializado.
                </p>
              </animated.div>
            )}
          </div>
        </div>
        <div className="mt-8">
          <button
            onClick={handleInfoToggle}
            className="flex items-center text-indigo-600 hover:text-indigo-700"
          >
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
            ¿Cómo elegir el plan adecuado?
          </button>
        </div>
      </div>
    </div>
  );
}

export default SupportPlans;
