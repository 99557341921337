import React, { useState } from 'react';
import axios from 'axios';

const Dominios = () => {
  const [domain, setDomain] = useState('');
  const [status, setStatus] = useState('');
  const [pricing, setPricing] = useState(null);

  const checkDomain = async () => {
    try {
      const response = await axios.post('http://localhost:4000/api/check-domain', { domain });

      if (response.data) {
        setStatus(response.data.responseMsg);
        setPricing(response.data.responseData); // Guardar la información del precio
      } else {
        setStatus('No se pudo verificar el dominio.');
        setPricing(null);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setStatus('Error verificando el dominio.');
      setPricing(null);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Buscar y Comprar un Dominio en Minutos
        </h2>
        <p className="mt-4 text-xl">
          Utiliza nuestra herramienta de verificación de dominios para encontrar el nombre perfecto para tu proyecto en línea.
        </p>
        <div className="mt-8 flex justify-center">
          <input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Introduce un dominio"
            className="border border-gray-300 p-2 rounded-l-md focus:outline-none"
          />
          <button
            onClick={checkDomain}
            className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700"
          >
            Buscar
          </button>
        </div>
        {status && (
          <div className="mt-4">
            <p className="text-lg text-gray-700">{status}</p>
            {pricing && (
              <div className="mt-4">
                <p className="text-lg text-gray-700">
                  Tipo de dominio: {pricing.domainType} <br />
                  Disponibilidad: {pricing.available ? 'Disponible' : 'No disponible'} <br />
                  Tarifas de registro: {pricing.registrationFees} <br />
                  Tarifas de renovación: {pricing.renewalFees} <br />
                  Tarifas de transferencia: {pricing.transferFees} <br />
                </p>
              </div>
            )}
          </div>
        )}
        <div className="mt-8">
          <p className="text-gray-500">
            La protección de privacidad WHOIS gratuita está incluida con cada registro de dominio elegible.
          </p>
        </div>
        <div className="mt-4">
          <a href="/src/components/hosting-cloud/HostingCloud.js" className="text-indigo-600 hover:underline">
            ¿Ya compraste un dominio? Transfiérelo
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dominios;
