import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaWordpress, FaElementor, FaNodeJs, FaReact, FaPython } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const titleSpring = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-20px)' }, delay: 100 });
  const subtitleSpring = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-20px)' }, delay: 300 });
  const textSpring = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-20px)' }, delay: 500 });
  const buttonSpring = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-20px)' }, delay: 700 });

  const handleComenzarClick = () => {
    navigate('/services'); // Asegúrate de que la ruta coincide con la configurada en App.js
  };

  return (
    <div className="bg-white min-h-screen flex items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <animated.h1 style={titleSpring} className="text-5xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-6xl">
            KUV Digital
          </animated.h1>
          <animated.h2 style={subtitleSpring} className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-4">
            Potenciamos tu Marca
          </animated.h2>
          <animated.p style={textSpring} className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Con nuestro desarrollo web, combinado con nuestro hosting profesional y de alta calidad, aseguramos una integración perfecta con las tecnologías más avanzadas, optimizando tu sitio web o aplicación para resultados excepcionales. ¡Transforma tu presencia en línea con nosotros.!
          </animated.p>
          <animated.div style={buttonSpring} className="mt-8 flex justify-center">
            <button
              onClick={handleComenzarClick}
              className="inline-block bg-indigo-600 text-white px-8 py-3 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
            >
              Comenzar
            </button>
          </animated.div>
        </div>
        <div className="mt-16">
          <p className="text-center text-gray-400">Servicios Digitales</p>
          <div className="mt-6 flex justify-center space-x-6">
            <FaWordpress className="h-10 w-10 text-gray-500" />
            <FaElementor className="h-10 w-10 text-gray-500" />
            <FaNodeJs className="h-10 w-10 text-gray-500" />
            <FaReact className="h-10 w-10 text-gray-500" />
            <FaPython className="h-10 w-10 text-gray-500" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
