import React from 'react';
import { Link } from 'react-router-dom';

const CancellationConditions = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-5">
      <div className="max-w-7xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-extrabold text-center text-gray-900 mb-6">
          Condiciones de Cancelación de Productos y Servicios
        </h1>

        <p className="text-lg text-gray-700 mb-6">
          Fecha de última actualización: <strong>Diciembre 2024</strong>
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Introducción</h2>
          <p className="text-gray-700">
            Este documento establece las condiciones bajo las cuales los productos y servicios de KUV Digital pueden ser cancelados.
            Al utilizar nuestros servicios, usted acepta estas condiciones y entiende los procedimientos relacionados con la cancelación,
            tiempos de rescisión y eliminación de archivos asociados a los productos contratados.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Cancelación de Servicios de Hosting</h2>
          <p className="text-gray-700 mb-4">
            <strong>2.1. Derecho a Cancelación:</strong> Los clientes pueden cancelar sus servicios de hosting en cualquier momento. La cancelación
            puede ser solicitada a través de nuestro portal de cliente o por correo electrónico a nuestro equipo de soporte.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>2.2. Garantía de Devolución de Dinero:</strong> Ofrecemos una garantía de devolución de dinero de <strong>30 días</strong> para todos nuestros servicios de hosting.
            Si decides cancelar dentro de este período, recibirás un reembolso completo.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>2.3. Tiempos de Cancelación:</strong> Para servicios de hosting, el <strong>período de rescisión</strong> comienza a partir de la fecha
            de cancelación solicitada y será efectivo en un plazo máximo de <strong>30 días</strong> a partir de la solicitud.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>2.4. Reembolsos:</strong> Los reembolsos por cancelaciones no están disponibles para pagos realizados después de los 30 días de la compra. 
            Si se cancela un servicio anual dentro de este período, el reembolso se calculará proporcionalmente al período no utilizado del servicio.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>2.5. Eliminación de Archivos:</strong> Tras la cancelación del servicio de hosting, se procederá a la eliminación de todos los archivos
            relacionados con el servicio. Esta acción se llevará a cabo <strong>30 días después de la fecha de cancelación</strong>.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Cancelación de Dominios</h2>
          <p className="text-gray-700 mb-4">
            <strong>3.1. Derecho a Cancelación:</strong> Los dominios registrados a través de KUV Digital pueden ser cancelados en cualquier momento, pero deben
            tener en cuenta las políticas de la entidad registradora del dominio.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>3.2. Costos de Transferencia:</strong> Si decides transferir tu dominio a otro proveedor, se aplicará un costo de transferencia de <strong>$22 USD</strong>.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>3.3. Procedimiento de Cancelación:</strong> Para cancelar un dominio, el cliente debe enviar una solicitud a nuestro equipo de soporte.
            La cancelación será efectiva en un plazo de <strong>5 a 7 días hábiles</strong>, dependiendo de la política de la registradora.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>3.4. No Reembolsable:</strong> Los dominios no son reembolsables una vez que se haya realizado el pago, ya que están sujetos a la política de
            la registradora. Si el cliente decide cancelar un dominio registrado, este no recibirá un reembolso por los servicios ya prestados.
          </p>
        </section>

        <section className="mb-8">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Cancelación de Otros Servicios (Desarrollo Web, Soporte, etc.)</h2>
  
  <p className="text-gray-700 mb-4">
    <strong>4.1. Cancelación de Proyectos de Desarrollo Web:</strong> Los proyectos de desarrollo web, aplicaciones o personalización serán considerados cancelados si no se recibe respuesta del cliente dentro de un plazo razonable. En este caso, el sistema de KUV Digital iniciará automáticamente el proceso de desactivación y cancelación. Los servicios de desarrollo no son reembolsables una vez que se haya iniciado el trabajo, aunque los clientes pueden solicitar una modificación dentro de los primeros <strong>15 días</strong> del contrato.
  </p>

  <p className="text-gray-700 mb-4">
    <strong>4.2. Período de Rescisión de Servicios de Desarrollo:</strong> Si el cliente decide cancelar un proyecto antes de su finalización, el período de rescisión será de <strong>30 días</strong>, con un cargo proporcional a los avances y trabajo realizado hasta la fecha de la cancelación. En caso de no recibir respuesta del cliente, nuestro sistema iniciará automáticamente el proceso de desactivación final, y se procederá con la cancelación del servicio.
  </p>
</section>




        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Cómo Funcionan Nuestros Tiempos de Desactivación</h2>
          <p className="text-gray-700 mb-4">
            Los tiempos de desactivación dependen de la naturaleza del servicio que se está cancelando. A continuación, se describe el flujo de los procesos:
          </p>
          <ul className="list-disc pl-6 text-gray-700 mb-4">
            <li><strong>Compra de Producto:</strong> Una vez que un producto es comprado, se activa inmediatamente y es gestionado por KUV Digital.</li>
            <li><strong>No Pago:</strong> En caso de no recibir el pago a tiempo, se dará un período de 30 días para la resolución. Después de este período, el servicio será suspendido.</li>
            <li><strong>Cancelación:</strong> Si el cliente decide cancelar el servicio, se procederá con el cierre del servicio y la eliminación de los archivos asociados después de 30 días.</li>
            <li><strong>Rescisión y Eliminación:</strong> Si el servicio no es renovado o cancelado dentro de los plazos establecidos, se llevará a cabo la eliminación de los archivos relacionados con el servicio, según nuestras políticas de rescisión.</li>
          </ul>
          <p className="text-gray-700 mb-4">
            Para obtener más detalles sobre cómo funcionan nuestros tiempos de desactivación, haga clic en el botón de abajo.
          </p>
          <Link to="/how-to-work" className="inline-block bg-indigo-600 text-white py-2 px-6 rounded-full hover:bg-indigo-700">
            ¿Cómo Funciona Nuestro Proceso?
          </Link>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Tiempos de Rescisión y Cancelación Automática</h2>
          <p className="text-gray-700 mb-4">
            <strong>6.1. Rescisión de Servicios por No Pago:</strong> KUV Digital se reserva el derecho de cancelar o suspender servicios debido a la falta de pago,
            conforme a la fecha de vencimiento indicada en la factura. Una vez vencido el plazo de pago, el cliente recibirá un aviso de rescisión con un plazo de
            <strong>30 días</strong> para resolver el pago pendiente.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>6.2. Suspensión Automática:</strong> Si el pago no se recibe dentro del período de rescisión, los servicios serán <strong>suspendidos automáticamente</strong>
            y se procederá con la cancelación de los mismos después de <strong>30 días</strong> de no recibir el pago.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Políticas de Reembolsos</h2>
          <p className="text-gray-700 mb-4">
            <strong>7.1. Hosting y Dominios:</strong> No ofrecemos reembolsos por cancelaciones de hosting o dominios, excepto en casos excepcionales, como errores administrativos o cargos duplicados.
          </p>
          <p className="text-gray-700 mb-4">
            <strong>7.2. Desarrollo Web y Otros Servicios:</strong> Los servicios de desarrollo web, aplicaciones y otros productos de trabajo personalizado no son reembolsables una vez iniciado el trabajo,
            aunque se pueden negociar condiciones especiales dependiendo del progreso del proyecto.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Modificaciones de las Condiciones de Cancelación</h2>
          <p className="text-gray-700">
            KUV Digital se reserva el derecho de modificar estas condiciones en cualquier momento. Las modificaciones entrarán en vigor tan pronto como sean publicadas en nuestro sitio web.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Contacto</h2>
          <p className="text-gray-700">
            Si tiene alguna pregunta o desea iniciar el proceso de cancelación de alguno de nuestros servicios, por favor contacte a nuestro equipo de soporte a través del correo electrónico:
            <a href="mailto:soporte@kuvdigital.com" className="text-indigo-600 hover:underline">soporte@kuvdigital.com</a> o llámenos al <strong>+123-456-7890</strong>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CancellationConditions;
