import React from 'react';
import { FaServer, FaGlobe, FaMobileAlt, FaLaptopCode, FaTools, FaCog, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Servicios con descripciones ajustadas
const services = [
  { name: 'Soporte Técnico Online', icon: <FaTools />, description: 'Asistencia técnica remota para resolver problemas informáticos de manera rápida y eficiente.' },
  { name: 'Mantenimiento Web', icon: <FaCog />, description: 'Mantenimiento y optimización continua de tu sitio web, asegurando su buen funcionamiento y seguridad.' },
  { name: 'SEO y Gestión de Redes Sociales', icon: <FaChartLine />, description: 'Optimización para motores de búsqueda (SEO) y gestión de redes sociales para aumentar la visibilidad y el tráfico.' },
  { name: 'Alojamiento Web', icon: <FaServer />, description: 'Servicio de alojamiento web de alta calidad, garantizando la disponibilidad de tu sitio en todo momento.' },
  { name: 'Dominios', icon: <FaGlobe />, description: 'Registro y gestión de dominios, asegurando que tu sitio esté siempre disponible bajo tu nombre preferido.' },
  { name: 'Desarrollo de Aplicaciones', icon: <FaMobileAlt />, description: 'Desarrollo de aplicaciones móviles personalizadas para tu negocio, mejorando la experiencia de tus usuarios.' },
  { name: 'Desarrollo Web', icon: <FaLaptopCode />, description: 'Desarrollo de sitios web profesionales y a medida para tu empresa, con una presencia en línea efectiva y atractiva.' }
];

// Función para limitar la cantidad de caracteres
const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

const Card = ({ service }) => {
  const navigate = useNavigate();

  const handleSelect = (serviceName) => {
    switch(serviceName) {
      case 'Soporte Técnico Online':
        navigate('/soporte-tecnico');
        break;
      case 'Mantenimiento Web':
        navigate('/mantenimiento-web');
        break;
      case 'SEO y Gestión de Redes Sociales':
        navigate('/seo-redes');
        break;
      case 'Alojamiento Web':
        navigate('/hosting');
        break;
      case 'Dominios':
        navigate('/dominios');
        break;
      case 'Desarrollo de Aplicaciones':
        navigate('/desarrollo-aplicaciones');
        break;
      case 'Desarrollo Web':
        navigate('/desarrollo-web');
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
      <div className="text-4xl text-indigo-600 mb-4">{service.icon}</div>
      <h3 className="text-xl font-bold">{service.name}</h3>
      <p className="mt-2 text-gray-500">{truncateText(service.description, 100)}</p> {/* Limitar a 100 caracteres */}
      <button
        onClick={() => handleSelect(service.name)}
        className="mt-4 inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
      >
        Seleccionar
      </button>
    </div>
  );
};

const OurServices = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          ¿Qué tipo de servicio buscas?
        </h2>

        {/* Párrafo atractivo agregado */}
        <p className="text-lg text-gray-700 mb-8">
          En KUV Digital ofrecemos una amplia gama de servicios que pueden ayudarte a potenciar tu empresa. Desde soporte técnico, planes SEO y redes, hasta un poderoso sitio web o aplicación que optimice tus procesos.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {services.map((service) => (
            <Card key={service.name} service={service} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
