import React from 'react';
import { FaCheck } from 'react-icons/fa';

const features = [
  {
    title: 'Certificados SSL',
    description: 'Proteja todos sus sitios web, generando certificados de seguridad ilimitados y gratis de por vida.',
  },
  {
    title: 'cPanel & WHM',
    description: 'Administre todos sus datos en cualquier momento con nuestro CPanel Administrado.',
  },
  {
    title: 'LiteSpeed Cache',
    description: 'Sitios web con carga de datos ultra rápida con la tecnología de LightSpeed Cache.',
  },
  {
    title: 'Imunify 360',
    description: 'Imunify 360 protege todos los sitios web alojados contra infecciones de malware, ataques web.',
  },
  {
    title: 'Correo Corporativo',
    description: 'Obtenga hasta 100 cuentas de correo profesional.',
  },
  {
    title: 'Transferencias',
    description: 'Transfiera sus sitios web a KUV Digital con ayuda de nuestros expertos sin costos adicionales.',
  },
  {
    title: 'Máximo Rendimiento WordPress y Redindimiento Stantard WooCommerce',
    description: 'Hosting rápido y seguro para pequeñas y medianas empresas.',
  },
  {
    title: 'Panel de Control y API',
    description: 'Nuestro panel de control y API fáciles de usar le permiten conectar pasarelas de pago.',
  },
  {
    title: 'Soporte en Español 24/7',
    description: 'Soporte en directo y en español 24/7.',
  },
  {
    title: '30 Días de Garantía Money Back',
    description: 'Garantía de devolución de dinero de 30 días.',
  },
  {
    title: 'Escáner de Malware',
    description: 'Escáner de malware para detectar y eliminar archivos maliciosos.',
  },
  {
    title: 'Protección de la Privacidad',
    description: 'Protección de la privacidad para que tu información personal esté a salvo.',
  },
  {
    title: 'Bloqueo de IP y países',
    description: 'Bloqueo de IP y países para prevenir los accesos no deseados con la CDN de KUV Digital.',
  },
  {
    title: 'Sistema de Respaldos JetBackup',
    description: 'Sistema de Respaldos JetBackup.',
  },
];

const HostingShareFeatures = () => {
  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Nosotros ofrecemos</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Features Incluidos
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Hosting rápido y seguro para pequeñas y medianas empresas.
          </p>
        </div>
        <div className="mt-16">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.title} className="relative">
                <dt className="flex items-center">
                  <FaCheck className="flex-shrink-0 h-6 w-6 text-indigo-600" style={{ fontWeight: 300 }} />
                  <p className="ml-4 text-lg leading-6 font-medium text-gray-900">{feature.title}</p>
                </dt>
                <dd className="mt-2 ml-10 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default HostingShareFeatures;
