import React, { useState } from 'react';
import { CloudIcon, ServerIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';

const HostingShare = ({ onServiceSelect }) => {
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const infoSpring = useSpring({
    opacity: showInfo ? 1 : 0,
    transform: showInfo ? 'translateY(0)' : 'translateY(-20px)',
  });

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          ¿Qué tipo de servicio buscas?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <ServerIcon className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Shared Hosting</h3>
              <p className="mt-2 text-gray-500">
                Web hosting rápido y fiable. Hosting rápido y seguro para pequeñas y medianas empresas.
              </p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/hosting-share')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Shared Hosting</h3>
                <p className="mt-2 text-gray-700">
                  El Shared Hosting, o alojamiento compartido, es una solución de alojamiento web en la que varios sitios web comparten los mismos recursos de un servidor físico. Esto lo hace más económico y adecuado para pequeñas y medianas empresas. Sin embargo, los recursos compartidos pueden afectar el rendimiento si uno de los sitios en el servidor experimenta un alto tráfico.
                </p>
              </animated.div>
            )}
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <CloudIcon className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Cloud Hosting</h3>
              <p className="mt-2 text-gray-500">
                Hosting ¡Potente y fácil de usar! ¡Hospeda tu sitio web en un servidor web basado en la nube: potente y fácil de usar!
              </p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/hosting-cloud')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Cloud Hosting</h3>
                <p className="mt-2 text-gray-700">
                  El Cloud Hosting utiliza múltiples servidores virtuales en la nube para hospedar tu sitio web. Esto permite una mayor flexibilidad y escalabilidad, ya que los recursos pueden ajustarse dinámicamente según las necesidades. Es ideal para sitios web que requieren alta disponibilidad y pueden manejar grandes volúmenes de tráfico sin afectar el rendimiento.
                </p>
              </animated.div>
            )}
          </div>
        </div>
        <div className="mt-8">
          <button
            onClick={handleInfoToggle}
            className="flex items-center text-indigo-600 hover:text-indigo-700"
          >
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
            ¿Cómo elegir?
          </button>
        </div>
      </div>
    </div>
  );
}

export default HostingShare;
