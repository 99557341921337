import React, { useState } from 'react';
import { FaRegAddressCard, FaRegNewspaper, FaBuilding, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const WebDevelopmentPlans = () => {
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const infoSpring = useSpring({
    opacity: showInfo ? 1 : 0,
    transform: showInfo ? 'translateY(0)' : 'translateY(-20px)',
  });

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Planes de Desarrollo Web Personalizados para Tu Negocio
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          
          {/* Landing Page */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaRegAddressCard className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Landing Page</h3>
              <p className="mt-2 text-gray-500">Diseño atractivo y optimizado para captar leads desde el primer clic.</p>
              <p className="mt-4 text-gray-800 font-bold">Desde $300 USD</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/landing-page')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Landing Page</h3>
                <p className="mt-2 text-gray-700">
                  El Plan de Landing Page incluye un diseño atractivo y optimizado para la conversión de visitantes en clientes potenciales. Ideal para campañas publicitarias o promociones.
                </p>
              </animated.div>
            )}
          </div>

          {/* Blog */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaRegNewspaper className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Blog</h3>
              <p className="mt-2 text-gray-500">Desarrolla un blog profesional para impulsar tu contenido y aumentar tu audiencia.</p>
              <p className="mt-4 text-gray-800 font-bold">Desde $450 USD</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/blog')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Blog</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Blog incluye un desarrollo web enfocado en la creación de contenido y la optimización para SEO, ayudando a aumentar el tráfico web y mejorar la visibilidad en los motores de búsqueda.
                </p>
              </animated.div>
            )}
          </div>

          {/* Empresariales */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaBuilding className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Empresariales</h3>
              <p className="mt-2 text-gray-500">Desarrollo de sitios web funcionales y optimizados para empresas.</p>
              <p className="mt-4 text-gray-800 font-bold">Desde $650 USD</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/empresariales')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Empresariales</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Empresarial está diseñado para empresas que necesitan una página web profesional, con funcionalidades como formularios de contacto, integración con redes sociales, y optimización SEO básica.
                </p>
              </animated.div>
            )}
          </div>

          {/* Tiendas en Línea */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaShoppingCart className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Tiendas en Línea</h3>
              <p className="mt-2 text-gray-500">Desarrolla una tienda en línea completa con integración de pagos y gestión de productos.</p>
              <p className="mt-4 text-gray-800 font-bold">Desde $1,200 USD</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/tiendas-en-linea')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Tiendas en Línea</h3>
                <p className="mt-2 text-gray-700">
                  El Plan de Tienda en Línea incluye el desarrollo de un sitio web funcional y optimizado para e-commerce. Con funcionalidades como gestión de productos, pagos seguros y optimización para conversiones.
                </p>
              </animated.div>
            )}
          </div>
        </div>
        <div className="mt-8">
          <button
            onClick={handleInfoToggle}
            className="flex items-center text-indigo-600 hover:text-indigo-700"
          >
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
            ¿Cómo elegir el plan adecuado?
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopmentPlans;
