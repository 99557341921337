import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import kuvDigitalLogo from '../images/kuv-digital-logo.svg'; // Asegúrate de que la ruta sea correcta

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-black text-white sticky top-0 z-50"> {/* Añadir z-50 */}
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen}
              onClick={toggleMenu}
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              )}
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0">
              <Link to="/">
                <img className="h-8 w-auto" src={kuvDigitalLogo} alt="KUV Digital" />
              </Link>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {/* Enlaces agregados */}
                <Link to="/hosting" className="text-white px-3 py-2 rounded-md text-sm font-medium">Hosting</Link>
                <Link to="/desarrollo-web" className="text-white px-3 py-2 rounded-md text-sm font-medium">Desarrollo Web</Link>
                <Link to="/seo-redes" className="text-white px-3 py-2 rounded-md text-sm font-medium">SEO y Redes</Link>
                <Link to="/mantenimiento-web" className="text-white px-3 py-2 rounded-md text-sm font-medium">Mantenimiento Web</Link>
                <Link to="/soporte-tecnico" className="text-white px-3 py-2 rounded-md text-sm font-medium">Soporte Técnico</Link>
              </div>
            </div>
          </div>
          <div className="hidden sm:block sm:ml-6">
            <div className="flex items-center space-x-4">
              <a href="https://cliente.kuvdigital.com" className="text-gray-400 hover:text-white">Registrarme</a>
              <a href="https://cliente.kuvdigital.com" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Login Access →</a>
            </div>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="sm:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* Enlaces agregados en el menú móvil */}
            <Link to="/hosting" className="text-white block px-3 py-2 rounded-md text-base font-medium">Hosting</Link>
            <Link to="/desarrollo-web" className="text-white block px-3 py-2 rounded-md text-base font-medium">Desarrollo Web</Link>
            <Link to="/seo-redes" className="text-white block px-3 py-2 rounded-md text-base font-medium">SEO y Redes</Link>
            <Link to="/mantenimiento-web" className="text-white block px-3 py-2 rounded-md text-base font-medium">Mantenimiento Web</Link>
            <Link to="/soporte-tecnico" className="text-white block px-3 py-2 rounded-md text-base font-medium">Soporte Técnico</Link>
            <a href="https://cliente.kuvdigital.com" className="text-gray-400 block px-3 py-2 rounded-md text-base font-medium">Registrarme</a>
            <a href="https://cliente.kuvdigital.com" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Login Access →</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
