import React from 'react';
import { Link } from 'react-scroll';
import HostingSharePrecios from './HostingSharePrecios';
import HostingShareFeatures from './HostingShareFeatures';
import HostingShareCaracteristicas from './HostingShareCaracteristicas';

const HostingShare = () => {
  return (
    <div>
      <div className="bg-gradient-to-r from-blue-800 to-purple-700 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center text-white">
            <h2 className="text-lg font-semibold">Shared Hosting</h2>
            <h1 className="mt-2 text-5xl font-extrabold tracking-tight">
              Web hosting rápido y fiable.
            </h1>
            <p className="mt-4 text-xl">
              Hosting rápido y seguro para pequeñas y medianas empresas.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <Link
                to="precios-section"
                smooth={true}
                duration={500}
                className="inline-block bg-indigo-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 cursor-pointer"
              >
                Ver Planes
              </Link>
              <Link
                to="features-section"
                smooth={true}
                duration={500}
                className="inline-block bg-black text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 cursor-pointer"
              >
                Características
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <HostingSharePrecios />
          <div id="features-section">
            <HostingShareFeatures />
          </div>
          <div id="precios-section">
            <HostingShareCaracteristicas />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingShare;
