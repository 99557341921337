import React from 'react';
import { Link } from 'react-scroll';
import HostingCloudPrecios from './HostingCloudPrecios';
import HostingShareFeatures from '../hosting-share/HostingShareFeatures';
import HostingCloudCaracteristicas from './HostingCloudCaracteristicas';

const HostingCloud = () => {
  return (
    <div>
      <div className="bg-gradient-to-r from-blue-800 to-purple-700 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center text-white">
            <h2 className="text-lg font-semibold">Cloud Hosting</h2>
            <h1 className="mt-2 text-5xl font-extrabold tracking-tight">
              Hosting ¡Potente y fácil de usar!
            </h1>
            <p className="mt-4 text-xl">
              ¡Hospeda tu sitio web en un servidor web basado en la nube: potente y fácil de usar!
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <Link
                to="precios-section"
                smooth={true}
                duration={500}
                className="inline-block bg-indigo-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 cursor-pointer"
              >
                Ver Planes
              </Link>
              <Link
                to="features-section"
                smooth={true}
                duration={500}
                className="inline-block bg-black text-white font-semibold px-6 py-3 rounded-md hover:bg-indigo-700 cursor-pointer"
              >
                Características
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <HostingCloudPrecios />
          <div id="features-section">
            <HostingShareFeatures />
          </div>
          <div id="precios-section">
            <HostingCloudCaracteristicas />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingCloud;
