import React from 'react';

const HowItWorks = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-5">
      <div className="max-w-7xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-extrabold text-center text-gray-900 mb-6">
          ¿Cómo Funcionan Nuestros Tiempos de Cancelación y Rescisión?
        </h1>

        <p className="text-lg text-gray-700 mb-6">
          En KUV Digital, queremos asegurarnos de que entiendas claramente cómo funciona el proceso de cancelación, rescisión y eliminación de archivos relacionados con nuestros servicios. A continuación, te detallamos cómo funcionan nuestros plazos de cancelación y las condiciones relacionadas con los productos contratados.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Compra de Producto</h2>
          <p className="text-gray-700 mb-4">
            Cuando adquieres un producto o servicio a través de KUV Digital, el proceso comienza inmediatamente después de la confirmación del pago. Desde el momento de la compra, el producto o servicio se activa y estará disponible para su uso según las condiciones de tu contrato.
          </p>
          <p className="text-gray-700 mb-4">
            Ten en cuenta que los productos adquiridos no son reembolsables una vez que han sido activados, salvo en los casos donde se indique específicamente en nuestras políticas.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Política de No Pago</h2>
          <p className="text-gray-700 mb-4">
            Si no se recibe el pago dentro del período de vencimiento de la factura, tu producto o servicio será suspendido automáticamente. KUV Digital ofrece un período de rescisión de <strong>30 días</strong> para resolver el pago pendiente. Durante este tiempo, puedes realizar el pago para reactivar el servicio.
          </p>
          <p className="text-gray-700 mb-4">
            Si el pago no se recibe dentro de los 30 días, el producto será cancelado y eliminado. Asegúrate de realizar el pago a tiempo para evitar la cancelación automática.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Cancelación de Servicios</h2>
          <p className="text-gray-700 mb-4">
            Puedes cancelar cualquier servicio que hayas adquirido en KUV Digital en cualquier momento, siguiendo el procedimiento establecido. Para servicios de hosting, ofrecemos una <strong>garantía de devolución de dinero de 30 días</strong> desde la fecha de compra. Si decides cancelar dentro de este período, recibirás un reembolso completo.
          </p>
          <p className="text-gray-700 mb-4">
            Después de transcurridos los 30 días de garantía, no se emitirán reembolsos por servicios de hosting. En este caso, el servicio se cancelará al final del ciclo de facturación.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Cancelación de Dominios</h2>
          <p className="text-gray-700 mb-4">
            Los dominios registrados en KUV Digital pueden ser cancelados en cualquier momento. Sin embargo, existe un costo de transferencia de <strong>$22 USD</strong> que se aplicará si decides transferir el dominio a otro proveedor o registrador.
          </p>
          <p className="text-gray-700 mb-4">
            Recuerda que los dominios no son reembolsables una vez registrados. Si decides cancelar el servicio de dominio, este permanecerá activo hasta el final de su período de facturación.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Rescisión de Servicios</h2>
          <p className="text-gray-700 mb-4">
            Si un servicio no se paga en el tiempo establecido, entrará en un proceso de <strong>rescisión</strong>. El período de rescisión comienza después de los 30 días de no pago y tiene una duración de <strong>30 días adicionales</strong>.
          </p>
          <p className="text-gray-700 mb-4">
            Durante el período de rescisión, se le notificará sobre el estado de su cuenta y el riesgo de cancelación permanente. Si no se recibe el pago al finalizar el período de rescisión, el servicio será cancelado y los archivos relacionados serán eliminados de forma permanente.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Eliminación de Archivos</h2>
          <p className="text-gray-700 mb-4">
            Una vez que un servicio es cancelado y el período de rescisión ha finalizado, todos los archivos asociados a ese servicio serán eliminados permanentemente. Esto incluye cualquier archivo almacenado en el servidor, bases de datos y configuraciones específicas relacionadas con el producto o servicio cancelado.
          </p>
          <p className="text-gray-700 mb-4">
            Te recomendamos hacer una copia de seguridad de todos tus datos antes de cancelar cualquier servicio para evitar la pérdida de información importante.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Tiempos de Cancelación y Rescisión</h2>
          <p className="text-gray-700 mb-4">
            A continuación se detallan los tiempos estándar de cancelación, rescisión y eliminación de archivos:
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li><strong>Compra de Producto:</strong> El producto o servicio se activa inmediatamente tras la compra y confirmación del pago.</li>
            <li><strong>No Pago:</strong> Si no se recibe el pago, el producto entra en un período de rescisión de <strong>30 días</strong>.</li>
            <li><strong>Cancelación:</strong> Puedes cancelar el servicio en cualquier momento. Si es un servicio de hosting, puedes obtener un reembolso completo dentro de los primeros <strong>30 días</strong>.</li>
            <li><strong>Rescisión:</strong> Después del período de rescisión de <strong>30 días</strong>, si no se realiza el pago, el servicio será cancelado y los archivos eliminados permanentemente.</li>
            <li><strong>Eliminación de Archivos:</strong> Los archivos serán eliminados permanentemente después de la cancelación y finalización del período de rescisión, generalmente dentro de <strong>30 días</strong> después de la cancelación.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Contacto</h2>
          <p className="text-gray-700">
            Si tienes alguna pregunta o deseas discutir más sobre nuestros plazos de cancelación y rescisión, por favor contacta a nuestro equipo de soporte:
            <a href="mailto:soporte@kuvdigital.com" className="text-indigo-600 hover:underline">soporte@kuvdigital.com</a>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default HowItWorks;
