import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

const plans = [
  {
    name: 'Single',
    priceMonthly: '$9.02',
    priceAnnually: '$54.17',
    renewalMonthly: '$9.02',
    renewalAnnually: '$108.34',
    features: [
      { feature: '1 Sitio Web', included: true },
      { feature: 'Almacenamiento SSD de 50 GB', included: true },
      { feature: 'Copias de seguridad semanales', included: true },
      { feature: 'WooCommerce', included: false },
    ],
    url: 'https://cliente.kuvdigital.com/order/product?catid=15196e02-e513-6d42-d72b-e429807875d3&pid=9293e8d5-69d0-7245-802b-84e853121607&qty=1&bcm=1',
  },
  {
    name: 'Premium',
    priceMonthly: '$19.18',
    priceAnnually: '$89.76',
    renewalMonthly: '$19.18',
    renewalAnnually: '$179.51',
    features: [
      { feature: '100 Sitios Web', included: true },
      { feature: 'Almacenamiento SSD de 100 GB', included: true },
      { feature: 'Copias de seguridad diarias', included: true },
      { feature: 'WooCommerce Básico', included: true },
    ],
    popular: true,
    url: 'https://cliente.kuvdigital.com/order/product?catid=15196e02-e513-6d42-d72b-e429807875d3&pid=261e5098-973d-2479-285f-145e610832d7&qty=1&bcm=1',
  },
  {
    name: 'Business',
    priceMonthly: '$23.80',
    priceAnnually: '$117.49',
    renewalMonthly: '$23.80',
    renewalAnnually: '$234.97',
    features: [
      { feature: '300 Sitios Web', included: true },
      { feature: 'Almacenamiento SSD de 200 GB', included: true },
      { feature: 'Copias de seguridad diarias', incluido: true },
      { feature: 'WooCommerce Standard', included: true },
    ],
    url: 'https://cliente.kuvdigital.com/order/product?catid=15196e02-e513-6d42-d72b-e429807875d3&pid=01e96d29-8537-d4e1-5d5c-e4e120637085&qty=1&bcm=1',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const HostingSharePrecios = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mt-8">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Planes y Precios
          </h2>
          <p className="mt-2 text-lg text-gray-500">Nuestro mejor precio</p>
          <div className="flex justify-center mt-4">
            <Switch.Group as="div" className="flex items-center">
              <Switch.Label as="span" className="mr-3 text-gray-900">
                Mensual
              </Switch.Label>
              <Switch
                checked={isAnnual}
                onChange={setIsAnnual}
                className={classNames(
                  isAnnual ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 items-center rounded-full'
                )}
              >
                <span
                  className={classNames(
                    isAnnual ? 'translate-x-6' : 'translate-x-1',
                    'inline-block h-4 w-4 transform rounded-full bg-white transition'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-gray-900">
                Anual
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>
        <div className="mt-16 mb-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={classNames(
                'flex flex-col rounded-lg shadow-lg overflow-hidden',
                plan.popular ? 'border-4 border-indigo-500' : 'border border-gray-200'
              )}
            >
              <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <h3 className="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                  {plan.name}
                </h3>
                <div className="mt-4 flex items-center justify-center">
                  <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                    <span className="mt-2 mr-2 text-4xl font-medium">$</span>
                    <span className="font-extrabold">{isAnnual ? plan.priceAnnually : plan.priceMonthly}</span>
                  </span>
                </div>
                <p className="text-center text-lg text-gray-500">{isAnnual ? 'Anualmente I.V.A' : 'Mensualmente I.V.A'}</p>
                {isAnnual && (
                  <div className="text-center mt-2">
                    <div>
                      <span className="inline-block text-sm text-red-600">
                        Antes <span className="line-through">{plan.renewalAnnually}</span>
                      </span>
                    </div>
                    <div>
                      <span className="inline-block bg-red-600 text-white text-sm px-2 py-1 rounded-full mt-2">Ahorra un 50%</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1 flex flex-col justify-between p-6 bg-gray-50 sm:p-10 sm:pt-6">
                <ul className="space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature.feature} className="flex items-center">
                      {feature.included ? (
                        <CheckIcon className="flex-shrink-0 h-6 w-6 text-purple-500" />
                      ) : (
                        <XIcon className="flex-shrink-0 h-6 w-6 text-red-500" />
                      )}
                      <span className="ml-3 text-base font-medium text-gray-500">{feature.feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <div className="rounded-lg">
                    <a
                      href={plan.url}
                      className="block w-full text-center rounded-lg bg-indigo-600 text-white px-6 py-3 text-base font-medium hover:bg-indigo-700"
                    >
                      Ver Planes
                    </a>
                    <p className="text-center text-sm text-gray-500 mt-2">
                      {isAnnual ? `${plan.priceAnnually}/mes Al Renovar` : `Renueva en ${plan.renewalMonthly}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default HostingSharePrecios;
