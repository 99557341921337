import React, { useState } from 'react';
import { FaSearch, FaChartLine, FaBullseye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const SEOPlans = () => {
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const infoSpring = useSpring({
    opacity: showInfo ? 1 : 0,
    transform: showInfo ? 'translateY(0)' : 'translateY(-20px)',
  });

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          **Planes SEO Efectivos para Mejorar tu Posicionamiento Web** | Optimiza tu Visibilidad
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          Mejora tu presencia online con nuestros planes de SEO personalizados. Optimiza tu sitio web, aumenta tu visibilidad en Google y lleva tu negocio al siguiente nivel con nuestras soluciones SEO efectivas.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {/* Plan Básico SEO */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaSearch className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Básico SEO</h3>
              <p className="mt-2 text-gray-500">Optimización inicial del sitio web y análisis preliminar para mejorar tu visibilidad en Google.</p>
              <p className="mt-4 text-gray-800 font-bold">$250 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/seo-basico')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Básico SEO</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Básico SEO incluye una optimización inicial de tu sitio web, con un análisis preliminar de SEO y recomendaciones para mejorar la visibilidad. Este plan es ideal para pequeñas empresas o sitios web que recién comienzan con SEO.
                </p>
              </animated.div>
            )}
          </div>

          {/* Plan Avanzado SEO */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaChartLine className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Avanzado SEO</h3>
              <p className="mt-2 text-gray-500">Optimización más profunda, análisis de competencia, y soporte para campañas publicitarias SEM.</p>
              <p className="mt-4 text-gray-800 font-bold">$500 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/seo-avanzado')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Avanzado SEO</h3>
                <p className="mt-2 text-gray-700">
                  Con el Plan Avanzado SEO, tu sitio web recibirá una optimización más profunda con un análisis detallado de la competencia. Además, recibirás soporte para campañas SEM (Google Ads) para incrementar el tráfico y mejorar el rendimiento de tu sitio web.
                </p>
              </animated.div>
            )}
          </div>

          {/* Plan Premium SEO */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaBullseye className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Premium SEO</h3>
              <p className="mt-2 text-gray-500">Optimización continua, gestión de campañas SEM y soporte prioritario para resultados rápidos.</p>
              <p className="mt-4 text-gray-800 font-bold">$835 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/seo-premium')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Premium SEO</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Premium SEO incluye optimización continua para mantener tu sitio web a la vanguardia de los algoritmos de búsqueda. Además, gestionamos tus campañas SEM para maximizar la exposición en Google y otros motores de búsqueda. Ideal para empresas que necesitan resultados rápidos y un soporte constante.
                </p>
              </animated.div>
            )}
          </div>
        </div>
        <div className="mt-8">
          <button
            onClick={handleInfoToggle}
            className="flex items-center text-indigo-600 hover:text-indigo-700"
          >
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
            ¿Cómo elegir el plan adecuado?
          </button>
        </div>
      </div>
    </div>
  );
};

export default SEOPlans;
