import React, { useState } from 'react';
import { FaWrench, FaTools, FaLaptopCode } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const MaintenancePlans = () => {
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const infoSpring = useSpring({
    opacity: showInfo ? 1 : 0,
    transform: showInfo ? 'translateY(0)' : 'translateY(-20px)',
  });

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Planes de Mantenimiento Web Profesional | Optimiza tu Sitio Web para un Rendimiento Óptimo
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          El mantenimiento web es esencial para mantener tu sitio seguro, rápido y funcionando a su máximo potencial. Descubre nuestros planes de mantenimiento web y asegura que tu página siempre esté a la altura de las expectativas de tus usuarios.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {/* Plan Básico Mantenimiento Web */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaWrench className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Básico de Mantenimiento Web</h3>
              <p className="mt-2 text-gray-500">Mantenimiento básico mensual para asegurar el funcionamiento estable de tu sitio web.</p>
              <p className="mt-4 text-gray-800 font-bold">$300 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/mantenimiento-basico')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Básico de Mantenimiento Web</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Básico de Mantenimiento Web incluye actualizaciones periódicas de software, backups mensuales, y la resolución de incidencias menores. Ideal para sitios pequeños y medianos que requieren un mantenimiento regular y económico.
                </p>
              </animated.div>
            )}
          </div>

          {/* Plan Intermedio Mantenimiento Web */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaTools className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Intermedio de Mantenimiento Web</h3>
              <p className="mt-2 text-gray-500">Mantenimiento avanzado con optimización de rendimiento y seguridad.</p>
              <p className="mt-4 text-gray-800 font-bold">$550 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/mantenimiento-intermedio')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Intermedio de Mantenimiento Web</h3>
                <p className="mt-2 text-gray-700">
                  El Plan Intermedio de Mantenimiento Web ofrece optimización de rendimiento, análisis de seguridad, y actualizaciones periódicas avanzadas. Este plan es ideal para sitios web profesionales o de e-commerce que requieren una administración más detallada.
                </p>
              </animated.div>
            )}
          </div>

          {/* Plan Pro Mantenimiento Web */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
            <div className="text-center">
              <FaLaptopCode className="h-12 w-12 text-indigo-600 mx-auto" />
              <h3 className="text-xl font-bold">Plan Pro de Mantenimiento Web</h3>
              <p className="mt-2 text-gray-500">Mantenimiento avanzado con soporte prioritario y mejoras continuas en el rendimiento del sitio web.</p>
              <p className="mt-4 text-gray-800 font-bold">$835 USD/mes</p>
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate('/mantenimiento-pro')}
                className="mt-auto inline-block bg-indigo-600 text-white px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-indigo-700"
              >
                Seleccionar
              </button>
            </div>
            {showInfo && (
              <animated.div style={infoSpring} className="mt-4 bg-gray-100 p-4 rounded-lg text-left">
                <h3 className="text-xl font-bold text-gray-900">Plan Pro de Mantenimiento Web</h3>
                <p className="mt-2 text-gray-700">
                  Con el Plan Pro de Mantenimiento Web, tu sitio recibirá optimización continua, análisis de seguridad avanzado, soporte prioritario, y ajustes regulares para asegurar que tu sitio web esté siempre actualizado y funcione al máximo rendimiento. Es ideal para empresas de mayor tamaño o proyectos web de alto tráfico.
                </p>
              </animated.div>
            )}
          </div>
        </div>
        <div className="mt-8">
          <button
            onClick={handleInfoToggle}
            className="flex items-center text-indigo-600 hover:text-indigo-700"
          >
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
            ¿Cómo elegir el plan adecuado?
          </button>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePlans;
