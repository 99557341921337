import React from 'react';
import facturalogoBlanco from '../images/kuvlogoblanco.svg'; // Asegúrate de que la ruta sea correcta

const Footer = () => {
  return (
    <footer className="bg-black text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* Columna de Imagen */}
          <div className="flex flex-col items-center sm:items-start">
            <img className="h-12 w-auto mb-4" src={facturalogoBlanco} alt="KUV Digital" />
            <p className="text-sm text-center sm:text-left">Tu aliado digital</p>
          </div>

          {/* Columna de Quick Links */}
          <div className="flex flex-col sm:items-start items-center">
            <h3 className="text-lg font-semibold mb-4">Enlaces Rápidos</h3>
            <ul className="space-y-2 text-sm">
              <li><a href="#" className="hover:text-gray-400">Inicio</a></li>
              <li><a href="/our-services" className="hover:text-gray-400">Servicios</a></li>
              <li><a href="/desarrollo-web" className="hover:text-gray-400">Proyectos</a></li>
              <li><a href="#" className="hover:text-gray-400">Contacto</a></li>
            </ul>
          </div>

          {/* Columna de Soporte */}
          <div className="flex flex-col sm:items-start items-center">
            <h3 className="text-lg font-semibold mb-4">Soporte</h3>
            <p className="text-sm">Soporte Técnico</p>
            <p className="text-sm">dev@kuvdigital.com</p>
          </div>

          {/* Columna de Políticas KUV */}
          <div className="flex flex-col sm:items-start items-center">
            <h3 className="text-lg font-semibold mb-4">Políticas KUV</h3>
            <ul className="space-y-2 text-sm">
              <li><a href="/cancelaciones" className="hover:text-gray-400">Política de Seguridad</a></li>
              <li><a href="/cancelaciones" className="hover:text-gray-400">Política de Privacidad</a></li>
              <li><a href="/cancelaciones" className="hover:text-gray-400">Política de Devolución</a></li>
              <li><a href="/cancelaciones" className="hover:text-gray-400">Política de Cancelación</a></li>
            </ul>
          </div>
        </div>

        {/* Redes Sociales */}
        <div className="mt-8 border-t border-gray-700 pt-8 text-center">
          <div className="flex justify-center space-x-6">
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M22.675 0h-21.35c-.733 0-1.325.593-1.325 1.326v21.348c0 .733.592 1.326 1.325 1.326h11.494v-9.284h-3.118v-3.622h3.118v-2.671c0-3.1 1.894-4.787 4.659-4.787 1.325 0 2.464.099 2.795.143v3.243l-1.918.001c-1.504 0-1.796.714-1.796 1.762v2.309h3.587l-.467 3.622h-3.12v9.284h6.112c.733 0 1.325-.593 1.325-1.326v-21.349c0-.733-.592-1.326-1.325-1.326z"/>
              </svg>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 2.163c3.204 0 3.584.012 4.849.07 1.366.062 2.633.313 3.608 1.288.974.975 1.225 2.242 1.287 3.608.059 1.265.071 1.645.071 13.465 0 11.82-.012 12.2-.071 13.465-.062 1.366-.313 2.633-1.287 3.608-.975.974-2.242 1.225-3.608 1.287-1.265.059-1.645.071-4.849.071-3.204 0-3.584-.012-4.849-.071-1.366-.062-2.633-.313-3.608-1.287-.974-.975-1.225-2.242-1.287-3.608-.059-1.265-.071-1.645-.071-13.465 0-11.82.012-12.2.071-13.465.062-1.366.313-2.633 1.287-3.608.975-.974 2.242-1.225 3.608-1.287 1.265-.059 1.645-.071 4.849-.071zm0-2.163c-3.274 0-3.67.013-4.956.072-1.359.062-2.601.313-3.557 1.269-.957.956-1.208 2.198-1.269 3.557-.059 1.286-.072 1.682-.072 4.956s.013 3.67.072 4.956c.062 1.359.313 2.601 1.269 3.557.956.957 2.198 1.208 3.557 1.269 1.286.059 1.682.072 4.956.072s3.67-.013 4.956-.072c1.359-.062 2.601-.313 3.557-1.269.957-.956 1.208-2.198 1.269-3.557.059-1.286.072-1.682.072-4.956s-.013-3.67-.072-4.956c-.062-1.359-.313-2.601-1.269-3.557-.956-.957-2.198-1.208-3.557-1.269-1.286-.059-1.682-.072-4.956-.072zm0 15.7c-1.8 0-3.265-1.465-3.265-3.265s1.465-3.265 3.265-3.265 3.265 1.465 3.265 3.265-1.465 3.265-3.265 3.265zm0-5.3c-1.12 0-2.035.915-2.035 2.035s.915 2.035 2.035 2.035 2.035-.915 2.035-2.035-.915-2.035-2.035-2.035zm6.406-3.848c-.526 0-.952-.426-.952-.952s.426-.952.952-.952.952.426.952.952-.426.952-.952.952z"/>
              </svg>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M23.954 4.569c-.885.39-1.83.654-2.825.775 1.014-.608 1.794-1.569 2.163-2.724-.949.555-2.002.959-3.127 1.184-.896-.957-2.173-1.555-3.591-1.555-2.717 0-4.917 2.2-4.917 4.917 0 .39.045.765.127 1.124-4.083-.205-7.702-2.16-10.125-5.134-.423.725-.666 1.561-.666 2.457 0 1.693.862 3.183 2.175 4.059-.801-.025-1.555-.245-2.212-.612v.061c0 2.363 1.681 4.337 3.911 4.778-.41.111-.84.17-1.285.17-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.6 3.418-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.192 1.394 4.799 2.209 7.6 2.209 9.106 0 14.092-7.54 14.092-14.091 0-.214 0-.428-.015-.639.964-.695 1.8-1.562 2.457-2.549z"/>
              </svg>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">GitHub</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.726-4.042-1.614-4.042-1.614-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.84 1.236 1.84 1.236 1.07 1.835 2.807 1.305 3.492.998.107-.775.418-1.305.76-1.605-2.665-.305-5.466-1.335-5.466-5.93 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.526.117-3.176 0 0 1.01-.323 3.31 1.23.96-.267 1.98-.4 3-.405 1.02.005 2.04.138 3 .405 2.29-1.553 3.3-1.23 3.3-1.23.653 1.65.242 2.873.118 3.176.767.84 1.236 1.91 1.236 3.22 0 4.61-2.807 5.625-5.482 5.92.43.37.823 1.102.823 2.222 0 1.606-.015 2.896-.015 3.292 0 .322.218.694.825.576 4.765-1.587 8.205-6.084 8.205-11.386 0-6.627-5.373-12-12-12z"/>
              </svg>
            </a>
          </div>
          <p className="mt-8 text-sm text-gray-400">© 2024 KUV Digital. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
