import React from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

const plans = [
  {
    name: 'KUV Cloud Startup',
    price: '$30.60',
    renew: 'Renueva en $378.49',
    url: 'https://cliente.kuvdigital.com/order/product?pid=027831d6-350d-8249-d87a-949e176259e0',
    details: [
      '300 sitios web',
      '200 GB de almacenamiento NVMe',
      '3 GB RAM',
      '2 núcleos de CPU',
      'Copias de seguridad diarias (valor: $23.88)',
      'Email gratis',
      'SSL ilimitado gratis',
      'Ancho de banda ilimitado',
      'Dominio incluido ($7.99)',
      'CDN Gratis',
      'Dirección IP dedicada',
    ],
    detailsGray: [],
    managedWordPress: [
      'Instalación de WordPress con 1 clic gratis',
      'Migración de sitios web automática y gratis',
      'Plantillas prediseñadas gratis',
      'Aceleración de WordPress (LiteSpeed)',
      'Actualizaciones automáticas de WordPress',
      'Escáner de vulnerabilidades de WordPress',
      'Verificador de compatibilidad de WordPress',
      'Actualizaciones automáticas de WordPress',
    ],
    managedWordPressGray: [
      'WooCommerce Starter',
      'WordPress multisitio',
      'SSH y WP-CLI',
      'Plugin Amazon Afiliados para WordPress gratis',
      'Herramienta Staging de WordPress',
      'Object Caché para WordPress',
      'Backups a pedido',
    ],
    security: [
      'Protección DDoS estándar',
      'Firewall de aplicaciones web',
      'Namerservers protegidos con Cloudflare',
      'Escáner de malware',
      'Administrador de acceso seguro',
    ],
    securityGray: [
      'Protección de privacidad de dominio WHOIS gratis (valor: $7.99)',
    ],
    support: [
      'Garantía de reembolso de 30 días',
      'Garantía del 99,9% de uptime',
      'Centros de datos en todo el mundo',
      'Soporte 24/7',
    ],
    supportGray: ['Soporte prioritario'],
    technicalDetails: [
      '200 000 archivos y directorios (inodos)',
      '25 PHP workers',
      '~10 000 visitas al mes',
      '2 subdominios',
      '25 conexiones en MySQL MAX',
      '2 bases de datos',
      '1 cuenta FTP',
      '2 Cronjobs',
      'Acceso GIT',
      'Múltiples versiones PHP',
      'Administrador de DNS',
      'Administrador de caché',
      'Potente panel de control',
    ],
    technicalDetailsGray: ['Acceso SSH'],
  },
  {
    name: 'KUV Cloud Enterprise',
    price: '$75.80',
    renew: 'Renueva en $920.89',
    url: 'https://cliente.kuvdigital.com/order/product?pid=293e8d56-9d07-2455-120b-84e853121607',
    details: [
      '300 sitios web',
      '300 GB de almacenamiento NVMe',
      '12 GB RAM',
      '6 núcleos de CPU',
      'Copias de seguridad diarias (valor: $23.88)',
      'Email gratis',
      'SSL ilimitado gratis',
      'Ancho de banda ilimitado',
      'Dominio incluido ($7.99)',
      'CDN Gratis',
      'Dirección IP dedicada',
    ],
    detailsGray: [],
    managedWordPress: [
      'Instalación de WordPress con 1 clic gratis',
      'Migración de sitios web automática y gratis',
      'Plantillas prediseñadas gratis',
      'Aceleración de WordPress (LiteSpeed)',
      'Actualizaciones automáticas de WordPress',
      'Escáner de vulnerabilidades de WordPress',
      'Verificador de compatibilidad de WordPress',
      'Actualizaciones automáticas de WordPress',
    ],
    managedWordPressGray: [
      'WooCommerce Starter',
      'WordPress multisitio',
      'SSH y WP-CLI',
      'Plugin Amazon Afiliados para WordPress gratis',
      'Herramienta Staging de WordPress',
      'Object Caché para WordPress',
      'Backups a pedido',
    ],
    security: [
      'Protección DDoS estándar',
      'Firewall de aplicaciones web',
      'Namerservers protegidos con Cloudflare',
      'Escáner de malware',
      'Administrador de acceso seguro',
      'Protección de privacidad de dominio WHOIS gratis (valor: $7.99)',
    ],
    support: [
      'Garantía de reembolso de 30 días',
      'Garantía del 99,9% de uptime',
      'Centros de datos en todo el mundo',
      'Soporte 24/7',
    ],
    supportGray: ['Soporte prioritario'],
    technicalDetails: [
      '400 000 archivos y directorios (inodos)',
      '40 PHP workers',
      '~25 000 visitas al mes',
      '100 subdominios',
      '50 conexiones en MySQL MAX',
      'Bases de datos ilimitadas',
      'Cuentas FTP ilimitadas',
      'Cronjobs ilimitados',
      'Acceso GIT',
      'Múltiples versiones PHP',
      'Administrador de DNS',
      'Administrador de caché',
      'Potente panel de control',
      'Acceso SSH',
    ],
    technicalDetailsGray: [],
  },
  {
    name: 'KUV Cloud Professional',
    price: '$47.55',
    renew: 'Renueva en $581.89',
    url: 'https://cliente.kuvdigital.com/order/product?pid=50381d78-0e72-d4de-61ec-6413569926e5',
    details: [
      '300 sitios web',
      '250 GB de almacenamiento NVMe',
      '6 GB RAM',
      '4 núcleos de CPU',
      'Copias de seguridad diarias (valor: $23.88)',
      'Email gratis',
      'SSL ilimitado gratis',
      'Ancho de banda ilimitado',
      'Dominio incluido ($7.99)',
      'CDN Gratis',
      'Dirección IP dedicada',
    ],
    detailsGray: [],
    managedWordPress: [
      'Instalación de WordPress con 1 clic gratis',
      'Migración de sitios web automática y gratis',
      'Plantillas prediseñadas gratis',
      'Aceleración de WordPress (LiteSpeed)',
      'Actualizaciones automáticas de WordPress',
      'Escáner de vulnerabilidades de WordPress',
      'Verificador de compatibilidad de WordPress',
      'Actualizaciones automáticas de WordPress',
    ],
    managedWordPressGray: [
      'WooCommerce Starter',
      'WordPress multisitio',
      'SSH y WP-CLI',
      'Plugin Amazon Afiliados para WordPress gratis',
      'Herramienta Staging de WordPress',
      'Object Caché para WordPress',
      'Backups a pedido',
    ],
    security: [
      'Protección DDoS estándar',
      'Firewall de aplicaciones web',
      'Namerservers protegidos con Cloudflare',
      'Escáner de malware',
      'Administrador de acceso seguro',
      'Protección de privacidad de dominio WHOIS gratis (valor: $7.99)',
    ],
    support: [
      'Garantía de reembolso de 30 días',
      'Garantía del 99,9% de uptime',
      'Centros de datos en todo el mundo',
      'Soporte 24/7',
    ],
    supportGray: ['Soporte prioritario'],
    technicalDetails: [
      '600 000 archivos y directorios (inodos)',
      '60 PHP workers',
      '~100 000 visitas al mes',
      '100 subdominios',
      '75 conexiones en MySQL MAX',
      'Bases de datos ilimitadas',
      'Cuentas FTP ilimitadas',
      'Cronjobs ilimitados',
      'Acceso GIT',
      'Múltiples versiones PHP',
      'Administrador de DNS',
      'Administrador de caché',
      'Potente panel de control',
      'Acceso SSH',
    ],
    technicalDetailsGray: [],
  },
];

const HostingPlansComparison = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Elige el plan que mejor se ajuste a tus necesidades
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Comparativa de planes de hosting
          </p>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <div key={plan.name} className="border border-gray-200 rounded-lg shadow-sm p-6">
                <div className="text-center">
                  <h3 className="text-xl leading-6 font-medium text-gray-900">{plan.name}</h3>
                  <div className="mt-4">
                    <span className="text-4xl font-extrabold text-gray-900">{plan.price}</span>
                    <span className="text-base font-medium text-gray-500"> {plan.renew}</span>
                  </div>
                </div>
                <div className="mt-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">Detalles del Plan</h4>
                  <ul className="mt-4 space-y-4">
                    {plan.details.map((detail) => (
                      <li key={detail} className="flex items-start">
                        <CheckIcon className="h-6 w-6 text-purple-500" />
                        <p className="ml-3 text-base leading-6 text-gray-700">{detail}</p>
                      </li>
                    ))}
                    {plan.detailsGray && plan.detailsGray.map((detail) => (
                      <li key={detail} className="flex items-start">
                        <XIcon className="h-6 w-6 text-gray-300" />
                        <p className="ml-3 text-base leading-6 text-gray-500">{detail}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">WordPress Administrado</h4>
                  <ul className="mt-4 space-y-4">
                    {plan.managedWordPress.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <CheckIcon className="h-6 w-6 text-purple-500" />
                        <p className="ml-3 text-base leading-6 text-gray-700">{feature}</p>
                      </li>
                    ))}
                    {plan.managedWordPressGray && plan.managedWordPressGray.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <XIcon className="h-6 w-6 text-gray-300" />
                        <p className="ml-3 text-base leading-6 text-gray-500">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">Seguridad</h4>
                  <ul className="mt-4 space-y-4">
                    {plan.security.map((security) => (
                      <li key={security} className="flex items-start">
                        <CheckIcon className="h-6 w-6 text-purple-500" />
                        <p className="ml-3 text-base leading-6 text-gray-700">{security}</p>
                      </li>
                    ))}
                    {plan.securityGray && plan.securityGray.map((security) => (
                      <li key={security} className="flex items-start">
                        <XIcon className="h-6 w-6 text-gray-300" />
                        <p className="ml-3 text-base leading-6 text-gray-500">{security}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">Servicio y Soporte</h4>
                  <ul className="mt-4 space-y-4">
                    {plan.support.map((support) => (
                      <li key={support} className="flex items-start">
                        <CheckIcon className="h-6 w-6 text-purple-500" />
                        <p className="ml-3 text-base leading-6 text-gray-700">{support}</p>
                      </li>
                    ))}
                    {plan.supportGray && plan.supportGray.map((support) => (
                      <li key={support} className="flex items-start">
                        <XIcon className="h-6 w-6 text-gray-300" />
                        <p className="ml-3 text-base leading-6 text-gray-500">{support}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">Detalles Técnicos</h4>
                  <ul className="mt-4 space-y-4">
                    {plan.technicalDetails.map((detail) => (
                      <li key={detail} className="flex items-start">
                        <CheckIcon className="h-6 w-6 text-purple-500" />
                        <p className="ml-3 text-base leading-6 text-gray-700">{detail}</p>
                      </li>
                    ))}
                    {plan.technicalDetailsGray && plan.technicalDetailsGray.map((detail) => (
                      <li key={detail} className="flex items-start">
                        <XIcon className="h-6 w-6 text-gray-300" />
                        <p className="ml-3 text-base leading-6 text-gray-500">{detail}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-8">
                  <a href={plan.url} className="block w-full bg-indigo-600 text-white text-center font-semibold py-2 rounded-lg hover:bg-indigo-700">
                    Ver Planes
                  </a>
                  <p className="mt-2 text-center text-gray-500">{plan.renew}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostingPlansComparison;
